.body-profile {
  display: grid;
  padding: 2rem;
  grid-template-columns: 300px 1fr;
  gap: 1rem;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  font: 500 100%/1.5 system-ui;
}
img {
  max-width: 100%;
  height: auto;
}

.profile-pic {
  border-radius: 8%;
}

@media screen and (max-width: 650px) {
  .body-profile {
    display: block;
  }
  .profile-pic {
    width: 320px;
    height: 420px;
  }

  .bio-text {
    margin-top: 10%;
  }
}

@media screen and (max-width: 375px) {
  .profile-pic {
    width: 300px;
    height: 300px;
  }
}
