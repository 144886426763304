.home-background {
  /*background: url("https://michaelrivas.net/images/foodCartPhoto.JPG"); */
  /*position: fixed; */
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
}

.website-projects-container {
  margin-top: 8%;
}

/*.mainDiv {
  width: 100%;
}
*/
