.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(149, 149, 149, 0.94);
  border-radius: 2px;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.collageArt {
  width: 400px;
  height: 600px;
}
.sscLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  height: 600px;
  width: 600px;
}

.project-video-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  max-height: 600px;
}
.video-size {
  height: 400px;
  width: 600px;
}

.projects-container {
  max-width: 90%;
}

.writingHeader {
  margin-bottom: 4%;
  margin-top: 3%;
}

.emailDeveloper {
  height: 600px;
  width: 400px;
}

@media screen and (max-width: 900px) {
  img.sscLogo {
    width: 400px;
    height: 400px;
  }

  img.collageArt {
    width: 650px;
    height: 700px;
    margin-top: 3%;
    align-content: center;
  }

  img.bushwickPhoto {
    width: 650px;
    height: 400px;
    margin-top: 10px;
  }

  img.letterImage {
    width: 650px;
    height: 400px;
    margin-top: 20px;
  }

  .project-video-container {
    width: 600px;
    height: 400px;
  }
  .top-spacing {
    margin-top: 15%;
  }
}

@media screen and (max-width: 600px) {
  .project-video-container {
    width: 400px;
    max-height: 400px;
  }
  .video-size {
    height: 300px;
    width: 400px;
  }
  .top-spacing {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 375px) {
  .project-video-container {
    height: 300px;
    width: 350px;
  }
  .top-spacing {
    margin-top: 20%;
  }
  .video-size {
    height: 275px;
    width: 340px;
  }

  img.letterImage {
    height: 300px;
    width: 350px;
    margin-top: 2%;
  }

  img.bushwickPhoto {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    height: 300px;
    width: 350px;
  }
  .emailDeveloper {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    height: 800px;
    width: 400px;
  }

  img.collageArt {
    width: 200px;
    height: 300px;
    margin-top: 20px;
    align-content: center;
  }
  img.sscLogo {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 320px) {
  .project-video-container {
    height: 280px;
    width: 320px;
  }
  .top-spacing {
    margin-top: 30%;
  }
  .video-size {
    height: 275px;
    width: 300px;
  }
  img.bushwickPhoto {
    width: 320px;
    margin-top: 100px;
  }
  img.collageArt {
    width: 320px;
  }
  img.letterImage {
    margin-top: 100px;
  }
  h1 {
    margin-bottom: 50px;
  }

  .responsiveImage {
    background-size: cover;
    height: auto;
    width: 100%;
  }
}
