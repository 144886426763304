.card-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(128, 128, 128, 0.6);
  border-radius: 7px;
  color: white;
  margin-top: 18%;
  width: 50%;
}

@media screen and (max-width: 650px) {
  .card-style {
    display: block;
    min-width: 350px;
    margin-top: 30%;
  }
}

@media screen and (max-width: 375px) {
  .card-style {
    display: block;
    min-width: 300px;
    min-height: 50%;
    margin-top: 50%;
  }
}
